import React from "react";
import Route from "./pages/route";

import "./assets/css/style.css";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

function App() {
  return (
    // <div className="App">
    <Authenticator.Provider>
      <Route />
    </Authenticator.Provider>
    // </div>
  );
}

export default App;
