import React, { useEffect, useState } from "react";
import PageLayout from "./../layouts/PageLayout";
import SignalBlog from "./../components/Signal/SignalBlog";
import { Link, useNavigate } from "react-router-dom";
import TradingViewWidget from "../components/Signal/TradingViewWidget";
import Timer from "../components/Signal/Timer";
import moment from "moment/moment";
import axios from "axios";
const Signal = () => {
  const [newPrice, setNewPrice] = useState({
    // title: null,
    // subtitle: null,
    // price: null,
    // datetime: null,
    // signal: null,
  });

  const [pair, setPair] = useState("FOREXCOM:EURUSD");
  const [from, setFrom] = useState("EUR");
  const [base, setBase] = useState("USD");
  const [datetime, setDatetime] = useState("");
  const [history, setHistory] = useState(null);
  const [signal, setSignal] = useState(null);
  const pairArray = pair.split(":");

  useEffect(() => {
    const secondInterval = setInterval(() => {
      axios
        .get(
          `https://api.binance.com/api/v3/klines?symbol=BTCUSDT&interval=15m&limit=1`
        )
        .then((res) => {
          const cdata = res.data.map((d) => {
            return {
              time: d[0] / 1000,
              open: parseFloat(d[1]),
            };
          });
          setDatetime(
            moment
              .utc(moment.unix(cdata[0].time))
              .local()
              .format("YYYY-MM-DD HH:mm:ss")
          );
        })
        .catch((err) => console.log(err));
    }, 5000);
    return () => {
      clearInterval(secondInterval);
    };
  }, []);

  useEffect(() => {
    let historyData = moment().subtract(1, "days").format("YYYY-MM-DD");
    axios({
      url: `https://api.fastforex.io/historical?date=${historyData}&from=${from}&to=${base}&api_key=0dc95690d4-be5211b224-rwpvyc`,
      method: "get",
      headers: {
        accept: "application/json",
      },
    })
      .then((res) => {
        let p;
        if (base == "USD") {
          p = res.data.results.USD;
        } else if (base == "CAD") {
          p = res.data.results.CAD;
        } else if (base == "JPY") {
          p = res.data.results.JPY;
        } else if (base == "CHF") {
          p = res.data.results.CHF;
        }
        setHistory(p);
      })
      .catch((err) => console.log(err));

    axios({
      url: `https://api.fastforex.io/fetch-one?from=${from}&to=${base}&api_key=0dc95690d4-be5211b224-rwpvyc`,
      method: "get",
      headers: {
        accept: "application/json",
      },
    })
      .then((res) => {
        let p;
        if (base == "USD") {
          p = res.data.result.USD;
        } else if (base == "CAD") {
          p = res.data.result.CAD;
        } else if (base == "JPY") {
          p = res.data.result.JPY;
        } else if (base == "CHF") {
          p = res.data.result.CHF;
        }

        let s;
        if (p > history) {
          s = "CALL";
        } else {
          s = "PUT";
        }

        setNewPrice({
          ...newPrice,
          price: p,
          title: pairArray[1],
          datetime,
          subtitle: pairArray[1],
          signal: s,
        });
      })
      .catch((err) => console.log(err));
  }, [pair, datetime]);

  return (
    <>
      <div className="page-content">
        <PageLayout pageTitle="Signal" />
      </div>
      <section className="content-inner pricing-plan-wrapper bg-primary-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center btn-group">
              <Link
                to={"#"}
                onClick={() => {
                  setPair("FOREXCOM:EURUSD");
                  setFrom("EUR");
                  setBase("USD");
                }}
                className="btn btn-lg btn-primary btn-shadow text-uppercase"
              >
                EURUSD
              </Link>
              <Link
                to={"#"}
                onClick={() => {
                  setPair("FOREXCOM:GBPUSD");
                  setFrom("GBP");
                  setBase("USD");
                }}
                className="btn btn-lg btn-primary btn-shadow text-uppercase"
              >
                GBPUSD
              </Link>
              <Link
                to={"#"}
                onClick={() => {
                  setPair("FOREXCOM:USDJPY");
                  setFrom("USD");
                  setBase("JPY");
                }}
                className="btn btn-lg btn-primary btn-shadow text-uppercase"
              >
                USDJPY
              </Link>
              <Link
                to={"#"}
                onClick={() => {
                  setPair("FOREXCOM:USDCHF");
                  setFrom("USD");
                  setBase("CHF");
                }}
                className="btn btn-lg btn-primary btn-shadow text-uppercase"
              >
                USDCHF
              </Link>
              <Link
                to={"#"}
                onClick={() => {
                  setPair("FOREXCOM:USDCAD");
                  setFrom("USD");
                  setBase("CAD");
                }}
                className="btn btn-lg btn-primary btn-shadow text-uppercase"
              >
                USDCAD
              </Link>
              <Link
                to={"#"}
                onClick={() => {
                  setPair("FOREXCOM:AUDUSD");
                  setFrom("AUD");
                  setBase("USD");
                }}
                className="btn btn-lg btn-primary btn-shadow text-uppercase"
              >
                AUDUSD
              </Link>
              <Link
                to={"#"}
                onClick={() => {
                  setPair("FOREXCOM:NZDUSD");
                  setFrom("NZD");
                  setBase("USD");
                }}
                className="btn btn-lg btn-primary btn-shadow text-uppercase"
              >
                NZDUSD
              </Link>
            </div>
          </div>
          <div className="section-head text-center">
            <br />
            <h2 className="title">{pairArray[1]}</h2>
          </div>
          <TradingViewWidget pair={pair} />
          {/* <DynamicChartComponent
            data={initialData}
            onAddPrice={onAddNewPrice}
          /> */}
          <div className="section-head text-center">
            <br />
            {/* <h2 className="title">BTC/USD binary signal</h2> */}
          </div>
          <div className="row justify-content-center">
            <SignalBlog update={newPrice} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Signal;
