import React, { useState, useEffect, useInterval } from "react";
import { Link } from "react-router-dom";
import coin1 from "./../../assets/images/coins/coin1.png";
import coin3 from "./../../assets/images/coins/coin3.png";
import coin4 from "./../../assets/images/coins/coin4.png";
import moment from "moment";
import axios from "axios";
const priceData = [
  {
    image: coin4,
    title: "Bitcoin",
    subtitle: "BTC",
    price: "Loading..",
    percent: "Loading..",
    time: "Loading..",
  },
  // {
  //   image: coin3,
  //   title: "Ethereum",
  //   subtitle: "ETH",
  //   price: "1,122.44",
  //   percent: "-1.55",
  // },
  // {
  //   image: coin1,
  //   title: "Tether",
  //   subtitle: "USDT",
  //   price: "1.00",
  //   percent: "0.0099",
  // },
];

const SignalBlog = (props) => {
  const [historyPrice, setHistoryPrice] = useState(priceData);
  const [prevPrice, setPrevPrice] = useState(null);

  // const updateResult = [
  //   {
  //     image: coin4,
  //     title: "Bitcoin",
  //     subtitle: "BTC",
  //     price: Number(props.price).toFixed(2),
  //     percent: parseFloat(prevPrice) > parseFloat(props.price) ? "CALL" : "PUT",
  //     time: moment().format("YYYY-MM-DD HH:mm:ss"),
  //   },
  // ];
  // setHistoryPrice(updateResult);
  // setPrevPrice(Number(props.price).toFixed(2));

  return (
    <>
      {priceData.map((data, index) => (
        <div
          className="col-lg-4 col-md-6 m-b30 wow fadeInUp"
          data-wow-delay="0.2s"
          key={index}
        >
          <div className="icon-bx-wraper style-1 box-hover">
            <div className="icon-media">
              <img src={data.image} alt="" />
              <div className="icon-info">
                <h5 className="title">{props.update.title}</h5>
                <span>{props.update.subtitle}</span>
              </div>
            </div>
            <div className="icon-content">
              <ul className="price">
                <li>
                  <h6 className="mb-0 amount">${props.update.price}</h6>
                  <span
                    className={`percentage ${
                      index === 2 ? "text-green" : "text-red"
                    }`}
                  >
                    {props.update.signal}
                  </span>
                </li>
                <li>
                  {/* <span>Latest price</span> */}
                  <span>{props.update.datetime}</span>
                  <span>change</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default SignalBlog;
